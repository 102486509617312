<template>
  <div class="wrapperLogin">
    <div class="title-txt">CVBD·智慧可视化大屏</div>
    <div class="bgImg">
      <div class="login">
        <ul class="nav">
          <li :class="[activeName == 1 ? 'act' : '']" @click="change(1)">快捷登录</li>
          <li :class="[activeName == 2 ? 'act' : '']" @click="change(2)">密码登录</li>
          <li :class="[activeName == 3 ? 'act' : '']" @click="change(3)">扫码登录</li>
        </ul>
        <div v-if="activeName == 1">
          <div class="i_t">
            <el-input placeholder="请输入手机号" v-model.trim="userForm.phone" maxlength="11"></el-input>
            <img class="i_t_i" src="../assets/login/up.png" alt="">
          </div>
          <div class="i_ts">
            <el-input style="margin-top: 32px;" placeholder="请输入验证码" maxlength="4"
              v-model.trim="userForm.code"></el-input>
            <img class="i_t_is" src="../assets/login/ps.png" alt="">
            <div v-show="show" @click="getCode" class="postionRuYan">
              获取验证码
            </div>
            <div v-show="!show" class="postionRuYan postionRuMiao">
              {{ count }} s
            </div>
          </div>

        </div>
        <div v-if="activeName == 2">
          <div class="i_t">
            <el-input placeholder="请输入账号" v-model.trim="userForm.user"></el-input>
            <img class="i_t_i" src="../assets/login/up.png" alt="">
          </div>
          <div class="i_ts">
            <el-input style="margin-top: 32px;" placeholder="请输入密码" v-model.trim="userForm.password"></el-input>
            <img class="i_t_is" src="../assets/login/mm.png" alt="">
          </div>
        </div>
        <div v-if="activeName == 3" style="text-align: center; position: relative;">
          <img v-if="codes.ticket" class="ma"
            :src="'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + codes.ticket" alt="">
          <div @click="getWxQrCodes" v-if="wechatOrCodeText" class="yzm">{{ wechatOrCodeText }}</div>
          <div class="mat">支持微信扫码 安全登录</div>
        </div>
        <div class="logbtn" v-if="activeName != 3" @click="logins">登录</div>
        <!-- <el-form :model="userForm" :rules="rules" ref="userForm">
              <div class="userView">
                <div>
                  <div class="userViewTitle">账号</div>
                  <div>
                    <el-form-item prop="phone">
                      <el-input placeholder="请输入账号" v-model.trim="userForm.phone"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div style="margin-top: 40px">
                  <div class="userViewTitle">密码</div>
                  <div>
                    <el-form-item prop="password">
                      <el-input placeholder="请输入密码" v-model.trim="userForm.password" show-password></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="userButtonView">
                  <el-form-item>
                    <button class="userButton" @click.prevent="onClickRight('userForm')">登录</button>
                  </el-form-item>
                </div>
              </div>
            </el-form> -->
      </div>

    </div>
    <!-- <div class="userLoginView"> -->
    <!-- <div class="userTab">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="密码登录" name="1">
           
          </el-tab-pane>
          <el-tab-pane label="验证码登录" name="2">
            <div class="userView">
              <div>
                <div class="userViewTitle">手机号</div>
                <div>
                  <el-input placeholder="请输入手机号" maxlength="11" v-model="phone"></el-input>
                </div>
              </div>
              <div style="margin-top: 40px">
                <div class="userViewTitle">验证码</div>

                <div class="postionRu">
                  <el-input maxlength="6" placeholder="请输入验证码" v-model="code"></el-input>
                  <div v-show="show" @click="getCode" class="postionRuYan">
                    获取验证码
                  </div>
                  <div v-show="!show" class="postionRuYan postionRuMiao">
                    {{ count }} s
                  </div>
                </div>
              </div>
              <div class="userButtonView" @click="loginBy">
                <button class="userButton">登录</button>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div> -->
    <!-- </div> -->
    <el-dialog title="温馨提示:请绑定手机号" :visible.sync="dialogVisible" width="30%" round center :show-close="false">
      <div style="text-align: center;">
        <div class="i_t">
          <el-input placeholder="请输入手机号" v-model.trim="userForm.phone" maxlength="11"></el-input>
          <img class="i_t_i i_t_ii" src="../assets/login/up.png" alt="">
        </div>
        <div class="i_ts">
          <el-input style="margin-top: 32px;" placeholder="请输入验证码" maxlength="4"
            v-model.trim="userForm.code"></el-input>
          <img class="i_t_is i_t_iss" src="../assets/login/ps.png" alt="">
          <div v-show="show" @click="getCode" class="postionRuYan postionRuYans">
            获取验证码
          </div>
          <div v-show="!show" class="postionRuYan postionRuMiao">
            {{ count }} s
          </div>
        </div>
      </div>
      <div class="logbtn" @click="loginBy">绑定手机号</div>
    </el-dialog>
  </div>
</template>
<script>
// import { indexMenu } from "../../utils/http";
import { login, sendSms, getUserLoginStatus, loginByCode, getWxQrCode } from "../utils/http";

export default {
  name: "",
  components: {},
  props: [],
  data() {
    return {
      wxcode: 0,
      dialogVisible: false,
      userForm: {
        user: "",
        password: "",
        phone: '',
        code: '',
      },
      activeName: "1",
      input: "",
      show: true,
      count: "",
      timer: null,
      codes: '',
      wechatOrCodeText: '',
    };
  },
  computed: {},
  watch: {},
  created() { },
  mounted() { },
  methods: {
    logins() {
      if (this.activeName == 1) {
        this.loginBy()
      } else {
        this.onClickRight()
      }
      // sessionStorage.setItem("token", 1);
      // this.$router.push('/home')
    },
    change(id) {
      this.userForm = {
        user: "",
        password: "",
        phone: '',
        code: '',
      }
      this.activeName = id
      if (id == 3) {
        this.getWxQrCodes()
      }
    },
    getWxQrCodes() {
      getWxQrCode().then(res => {
        this.wechatOrCodeText = ''
        if (res.data.resultStates != 0) {
          this.$message.error('获取二维码失败')
          return
        }
        console.log(JSON.parse(res.data.content))
        console.log(res.data.content, '====')
        this.codes = JSON.parse(res.data.content)
        let count = 0
        let interval = setInterval(() => {
          count++
          // 当检查5次未扫码的时候，将二维码失效，重试关闭接口请求
          if (count > 5) {
            this.wechatOrCodeText = '二维码已经失效，请点击刷新',
              clearInterval(interval)
          }
          getUserLoginStatus({ ticket: this.codes.ticket }).then(res => {
            console.log("获取用户状态", res)
            if (res.code == 0) {
              if (res.data.content.token) {
                sessionStorage.setItem("token", res.data.content.token);
                this.$message.success("登录成功");
                this.$router.push("/home");
                this.dialogVisible = false
              } else {
                this.wxcode = 1
                this.wxcodes = res.data.content
                this.dialogVisible = true
              }
              clearInterval(interval)
            } else {

              // this.$message.error('登录失败请稍后再试')
            }
          });
        }, 3000);
      })
    },
    //  验证码 EqtCSgYy%MUk
    loginBy() {
      if (this.userForm.phone.length != 11 || !/^1\d{10}$/.test(this.userForm.phone)) {
        this.$message.error('手机号格式有误')
        return
      }
      if (!this.userForm.code) {
        this.$message.error('请输入验证码')
        return
      }
      let params = {}
      if (this.wxcode) {
        params = { phone: this.userForm.phone, code: this.userForm.code }
      } else {
        params = {
          phone: this.userForm.phone,
          code: this.userForm.code,
          openId: this.wxcode.openId,
          avatarUrl: this.wxcode.avatarUrl,
          nickName: this.wxcode.nickName,
          gender: this.wxcode.gender,
        }
      }
      loginByCode(params).then(res => {
        console.log(res,'=====')
        if (res.data.resultStates != 0) return this.$message.error(res.data.message);
        sessionStorage.setItem("token", res.data.content.token);
        this.$message.success("登录成功");
        this.$router.push("/home");
      })
    },
    async getCode() {
      if (this.userForm.phone.length != 11 || !/^1\d{10}$/.test(this.userForm.phone)) {
        this.$message.error('手机号格式有误')
        return
      }
      const { data: res } = await sendSms({ phone: this.userForm.phone })
      console.log(res)
      if (res.resultStates != 0) {
        this.$message.error(res.message)
        return
      }
      this.$message.success('验证码发送成功')
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    handleClick(tab, event) { },
    async onClickRight() {
      const { data: res } = await login({ phone: this.userForm.user, password: this.userForm.password });
      if (res.resultStates != 0) return this.$message.error(res.message);
      sessionStorage.setItem("token", res.content.token);
      this.$message.success("登录成功");
      this.$router.push("/home");
    },
  },
};
</script>
<style scoped lang='scss'>
.wrapperLogin {
  width: 100%;
  min-height: 700px;
  height: 100vh;
  background: url("../assets/login/login.jpg") no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.title-txt {
  width: 500px;
  height: 48px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 34px;
  color: #E4E4E4;
  line-height: 48px;
  text-align: left;
  font-style: normal;
  box-sizing: border-box;
  padding: 40px 0 0 40px;
}

.bgImg {
  width: 536px;
  height: 600px;
  background: url("../assets/login/lg.png") no-repeat;
  background-size: 100% 100%;
  // position: relative;
  position: absolute;
  top: 15%;
  right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login {
  height: 340px;
  width: 408px;
  cursor: pointer;
}

.nav {
  display: flex;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #A1A1A1;
  justify-content: space-between;
  margin-bottom: 40px;
}

.act {
  font-weight: 600;
  font-size: 24px;
  color: #F5F5F5;
}

/deep/ .el-input__inner {
  width: 408px;
  height: 60px;
  background: #003368;
  border-radius: 4px;
  border: 1px solid #05FAFD;
  text-indent: 50px;
  color: #FFFFFF;
  font-size: 22px;
}

.logbtn {
  width: 350px;
  height: 60px;
  background: #009DC8;
  border-radius: 4px;
  margin: 60px auto;
  text-align: center;
  line-height: 60px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 500;
  font-size: 22px;
  color: #FFFFFF;
}

.i_t {
  position: relative;
}

.i_ts {
  position: relative;
}

.i_t_i {
  position: absolute;
  width: 34px;
  height: 34px;
  left: 12px;
  top: 13px;
}

.i_t_is {
  position: absolute;
  width: 34px;
  height: 34px;
  left: 12px;
  top: 45px;
}

.postionRuYan {
  position: absolute;
  top: 45px;
  right: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 22px;
  color: #FFFFFF;
}

.postionRuYans {
  right: 70px;
}

.i_t_ii,
.i_t_iss {
  left: 64px;
}

.postionRuMiao {
  color: #999999;
}

.ma {
  width: 220px;
  height: 220px;
}

.mat {
  margin-top: 30px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 22px;
  color: #E4E4E4;
}

/deep/ .el-dialog {
  background: #17223F;
  border-radius: 20px;
}

/deep/ .el-dialog__title {
  color: #ffffff;
  font-size: 26px;

}

/deep/ .el-dialog__header {
  // padding-top: 40px;
}

/deep/ .el-dialog__body {
  padding-bottom: 10px;
}

.yzm {
  position: absolute;
  width: 220px;
  height: 220px;
  text-align: center;
  top: 0;
  background: rgba(0, 0, 0, .7);
  left: 94px;
  color: #ffffff;
  line-height: 220px;
}

// /deep/.el-tabs__nav-scroll {
//   display: flex;
//   justify-content: center;
//   height: 48px;
// }

// /deep/ .el-tabs__item {
//   font-size: 26px;
//   font-family: SourceHanSansCN-Medium, SourceHanSansCN;
//   font-weight: 500;
//   //   color: #026ce7;
//   color: #666666;
// }

// /deep/ .is-active {
//   color: #026ce7;
// }

// /deep/ .el-tabs__active-bar {
//   height: 4px;
//   border-radius: 2px;
//   width: 60px !important;
//   left: 36px;
// }

// /deep/ .el-tabs__item,
// .is-top {
//   width: 150px;
//   text-align: center;
// }



// .userLoginView {
//   position: absolute;
//   top: 13%;
//   right: 11%;
//   width: 540px;
//   height: 640px;
//   background: #ffffff;
//   box-shadow: 0px 2px 15px 0px #7eb0ff;
//   border-radius: 10px;
//   box-sizing: border-box;
//   padding: 38px 66px 0 66px;
// }

// /deep/.el-tabs__nav-wrap::after {
//   height: 0;
// }

// .userTop {
//   font-size: 24px;
//   font-family: SourceHanSansCN-Regular, SourceHanSansCN;
//   font-weight: 400;
//   color: #666666;
//   margin-bottom: 10px;
// }

// .userTitle {
//   width: 442px;
//   line-height: 51px;
//   font-size: 34px;
//   font-family: SourceHanSansCN-Medium, SourceHanSansCN;
//   font-weight: 500;
//   color: #333333;
// }

// .userTab {
//   width: 100%;
//   height: 512px;
//   box-sizing: border-box;
//   padding-top: 30px;
// }

// .userView {
//   width: 100%;
//   height: 100%;
//   box-sizing: border-box;
//   padding-top: 10px;
// }

// .userViewTitle {
//   font-size: 24px;
//   font-family: SourceHanSansCN-Regular, SourceHanSansCN;
//   font-weight: 400;
//   color: #666666;
// }

// /deep/ .el-input__inner {
//   width: 408px;
//   height: 56px;
//   background: none;
//   outline: 0;
//   border: none;
//   font-size: 30px;
//   font-family: DINAlternate-Bold, DINAlternate;
//   font-weight: bold;
//   color: #333333;
//   border-bottom: 1px solid #e8e8e8;
// }

// // .el-input,
// // .el-input__clear {
// //   font-size: 32px;
// // }
// /deep/.el-input .el-input__clear {
//   font-size: 32px;
//   color: #026ce7;
//   width: 50px;
// }

// /deep/.el-input__icon {
//   line-height: 56px;
// }

// .userButtonView {
//   width: 100%;
//   margin-top: 50px;
//   display: flex;
//   justify-content: center;
// }

// .userButton {
//   width: 350px;
//   height: 60px;
//   background: linear-gradient(214deg, #059ff4 0%, #0266e6 100%);
//   box-shadow: 0px 2px 10px 0px rgba(3, 107, 231, 0.6);
//   border-radius: 4px;
//   margin: 0 auto;
//   border: none;
//   font-size: 22px;
//   font-family: SourceHanSansCN-Medium, SourceHanSansCN;
//   font-weight: 500;
//   color: #ffffff;
// }

// /deep/.el-input__inner::-webkit-input-placeholder {
//   /* WebKit browsers */
//   font-size: 26px;
//   color: #999999;
//   font-weight: 400;
// }

// /deep/.el-input__inner:-moz-placeholder {
//   /* Mozilla Firefox 4 to 18 */
//   font-size: 26px;
//   color: #999999;
//   font-weight: 400;
// }

// /deep/.el-input__inner::-moz-placeholder {
//   /* Mozilla Firefox 19+ */
//   font-size: 26px;
//   color: #999999;
//   font-weight: 400;
// }

// /deep/.el-input__inner:-ms-input-placeholder {
//   /* Internet Explorer 10+ */
//   font-size: 26px;
//   color: #999999;
//   font-weight: 400;
// }

// .postionRu {
//   position: relative;
// }

// .postionRuYan {
//   cursor: pointer;
//   position: absolute;
//   right: 4%;
//   top: 28%;
//   font-size: 20px;
//   font-family: SourceHanSansCN-Medium, SourceHanSansCN;
//   font-weight: 500;
//   color: #026ce7;
// }</style>